import React, { Suspense } from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import svgMap from "../../assets/images/svg-map.svg";
import logo from "../../assets/Media/footerLogo.png";
import BackToTop from "../../components/Layout/backToTop";
import { FaWhatsapp } from "react-icons/fa";

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <Suspense fallback={Loader()}>
      <footer className="footer" style={{ backgroundImage: `url(${svgMap})`, backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-12">
              <div className="footer-py-30 text-center">
                <Link to="/" className="logo-footer">
                  <img src={logo} height="100" width='110' alt="" />
                </Link>
                <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                  <li className="list-inline-item "><Link to="https://www.facebook.com/original.co.eg" className="rounded" target="blank">
                    <FeatherIcon icon="facebook" className="fea icon-sm fea-social" /></Link></li>
                  <li className="list-inline-item "><Link to="https://www.linkedin.com/company/original-company-egy/" className="rounded" target="blank">
                    <FeatherIcon icon="linkedin" className="fea icon-sm fea-social" /></Link></li>
                  <li className="list-inline-item "><Link to="https://www.instagram.com/original.co.eg?igsh=enk2NnNpcGMwMnM1&utm_source=qr" className="rounded" target="blank">
                    <FeatherIcon icon="instagram" className="fea icon-sm fea-social" /></Link></li>
                  <li className="list-inline-item"><Link to="https://wa.me/+201557637141" className="rounded" target="blank">
                    <FaWhatsapp color="white"/></Link></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="py-2 footer-bar bg-footer">
          <Container className="text-center">
            <Row className="justify-content-center">
              <Col className="col-12">
                <div className="text-center">
                  <p className="mb-0"><span className="text-info">Original</span>  &copy; {(new Date().getFullYear())}  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
      <BackToTop />
    </Suspense>
  );
}

export default Footer;
