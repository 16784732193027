import React from 'react'
import Home from "../Views/Home/index";
import ContactUS from "../Views/ContactUs";
import Products from "../Views/Produtcs";
import Services from "../Views/Services"
import Rating from "../Views/Rating"

const routes = [
  { path: "/", component: <Home />, isWithoutLayout: true },
  { path: "/services", component: <Services />, isWithoutLayout: true },
  { path: "/products", component: <Products />, isWithoutLayout: true },
  { path: "/contact", component: <ContactUS />, isWithoutLayout: true },
  { path: "/:token/:id", component: <Rating />, isWithoutLayout: true },
];

export default routes;
