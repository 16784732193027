import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import img from '../../assets/Media/home1.svg'

const ourData =[
  'All services with very competitive and affordable prices.',
  'Extra discounts when agreeing to multiple services.',
  'We provide after-sales service.',
  'Guarantee on any system created for you.',
  'We provide all services with the highest possible quality.',
  'All websites work with the best performance.',
]

const Intro = () => {

  const [screen, setScreen] = useState();
  window.addEventListener('resize',()=>{
    setScreen(window.screen.width);
  })

  return (
    <Row className='mt-150 mb-5 justify-content-evenly'>
      <Col lg={5} md={12} className={`mt-4 pt-2 mt-sm-0 pt-sm-0  ${screen < 600 ? "d-none":"d-block"}`}>
        <img
          src={img}
          height={380}
          className="rounded mx-auto d-block"
          alt=""
        />
      </Col>
      <Col lg={6} md={7} sm={7} xs={11} className={`mt-4 pt-2 mt-sm-0 pt-sm-0`}>
        <div className="section-title ms-lg-4 mt-5">
          <h4 className="title mb-4">Why Us ?</h4>
            <ul className="list-unstyled text-muted">
              {
                ourData.map((item,key)=>{
                  return(<li key={key} className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>{item}</li>)
                })
              }
            </ul>
          <a href="#about" data-aos="fade-left" data-aos-offset="100" data-aos-duration="500" data-aos-delay='500' className="btn btn-primary mt-3">See More <i className="uil uil-angle-right-b"></i></a>
        </div>
      </Col>
    </Row>
  );
}

export default Intro;
