import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";

const SectionTtile = ({ isLeft , title , desc = undefined }) => {
    return (
      <Fragment>
        <Row className="justify-content-center">
          <Col className={isLeft ? "" : "col-12 text-center"}>
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4"> {title}</h4>
              {desc ? <p
                className={
                  isLeft
                    ? "text-muted para-desc mb-0"
                    : "text-muted para-desc mb-0 mx-auto"
                }
                name="sectiondesc"
              >Start working with {" "}
                <span className="text-primary fw-bold">Original</span> {desc}</p> : <></>
              }
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }

export default SectionTtile;
