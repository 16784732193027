import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Badge, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { FaArrowAltCircleRight , FaArrowAltCircleLeft  } from "react-icons/fa";
import { textTruncate } from '../../helpers/actions';
import { API } from '../../Api';
import SectionTtile from "../SharedComponents/SectionTtile"

const OurProducts = ({ setActiveProduct }) => {
  
  const [current, setCurrent] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [filter, setFilter] = useState('Web Development');
  const [screen, setScreen] = useState();

  const [products, setProducts] = useState([]);

  window.addEventListener('resize',()=>{
    setScreen(window.screen.width);
  })

  const handleCount = () =>{
    if(screen <= 1199 && screen > 700){
      return 2
    }else if(screen <= 700){
      return 1
    }
    else {
      return 3
    }
  }

  useEffect(() => {
    API.get(`products?filter[type]=${filter}`).then(res => {
      setProducts(res.data.data.data)
      setisLoading(false)
    })
  }, [filter])

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: handleCount(),
    slidesToScroll: 1,
    afterChange : (e) => {
      if(e +1 === products.length){
        setCurrent(-1)
      }
      else {setCurrent(e)}
    }
  };
  let sliderRef = useRef(null);

  const filters = [
    'Web Development',
    'Graphic Design' ,
    'Motion Graphics & Video Editing' ,
    'Marketing' ,
    'Mobile Applications' ,
    'Local Network'
  ]

  return (
    <Fragment>
      <Container className="mt-100 mt-60">
        <SectionTtile
          title="Our Products"
          desc=" that can provide everything you need to generate awareness, drive traffic, connect."
        />
      </Container>
      <Row>
        <div className="col-12 filters-group-wrap justify-content-center d-flex">
          <div className="filters-group">
            <ul
              className="container-filter list-inline mb-5 filter-options custom-categories-container-m17"
              id="filter"
            >
              {
                filters.map((f,index)=>{
                  return (
                    <li
                      onClick={() => setFilter(f)}
                      key={index}
                      className={`mx-2 list-inline-item categories-name border text-dark rounded custom-categories-m17 ${filter === f ? 'active' : ''}`}
                    >{f}
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </Row>
      {
        isLoading ? 
        <div className="d-flex justify-content-center mb-5">
          <div className="spinner-border" role="status"  style={{width:'3rem', height:'3rem'}}></div>
        </div>
        :
        products && products.length ?
        <Container fluid className="mb-md-5">
          <Row>
            <Slider ref={sliderRef} {...sliderSettings} className="owl-carousel owl-theme">
              {products.map((product, key) => (
                <div key={key}>
                  <Card className="border-0 work-container work-grid position-relative d-block overflow-hidden mx-3">
                    <CardBody className="p-0">
                      <div
                        className="mfp-image d-inline-block w-100"
                      >
                        <img
                          src={product.img}
                          className="img-fluid shadow rounded"
                          alt=""
                          style={{height:'300px', width:'100%'}}
                        />
                      </div>
                      <div className="content bg-white p-3 d-flex justify-content-between align-items-center">
                        <div>
                          <h5 className="mb-0">
                            <Link to="#" className="text-dark title">{product.title}</Link>
                          </h5>
                          <h6 className="text-muted tag mb-0">{textTruncate(product.desc , 20)}</h6>
                        </div>
                        <div>
                          <a href='#details' className='btn btn-primary' onClick={()=>setActiveProduct(product)}>
                            Read More
                          </a>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </Slider>
              <div className='d-flex justify-content-center align-items-center mt-3'>
                <div onClick={()=>sliderRef.current.slickPrev()}><FaArrowAltCircleLeft color='#2f55d4' cursor={'pointer'} size={40}/></div>
                <h4 className='mx-3 my-0'> { current + 2 } / {products.length}</h4>
                <div onClick={()=>sliderRef.current.slickNext()}><FaArrowAltCircleRight color='#2f55d4' cursor={'pointer'} size={40}/></div>
              </div>
          </Row>
        </Container>
        : <div className='w-100 text-center'> <Badge className='fs-2 p-3 mb-5 mt-2 mx-auto'>No Data.</Badge> </div>
      }
    </Fragment>
  );
}

export default OurProducts;
