import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Card, Col, Input } from 'reactstrap';
import { Rate } from 'antd';
import { API } from '../../Api';

const Index = () => {
  const { token , id } = useParams()
  const [desc, setDesc] = useState(undefined);
  const [rate, setRate] = useState(undefined);
  const navigate = useNavigate()

  const [alert, setAlert] = useState({
    text: '' ,
    color: 'info',
    isOpen : false
});

  const handleSubmit = ()=>{
    API.post(`clients/evalutaion`,{
      rate: rate.toString() ,
      details : desc,
      client_id : id,
      token : token
    })
    .then((res)=>{
      setAlert({
        text: 'Thank You !',
        color: 'success',
        isOpen: true
    })
    setTimeout(() => {
      navigate('/')
    }, 2000);
    })
    .catch((err)=>{
      if(err.response.status === 500){
        setAlert({
          text: 'You Dont have This permission',
          color: 'danger',
          isOpen: true
        })
      }else {
        setAlert({
          text: err.response.data.message || 'Failed to send Your Request details',
          color: 'danger',
          isOpen: true
        })
      }
    })
  }

  return (
    <Col lg='9' md='9' sm='11' xs='11' className='mx-auto mt-150'>
      <Card className='p-4 fs-4 rounded shadow-lg'>
        <Alert color={alert.color} isOpen={alert.isOpen} > {alert.text} </Alert>
        <h4 className="mb-5 mx-auto fw-bold text-info" >How satisfied are you with our services ?</h4>
        <h6 className="InputHandler_label mb-0 mx-auto" >Rate Us</h6>
        <Rate allowHalf defaultValue={0} className='fs-1 mx-auto' onChange={(e)=>setRate(e)}/>
        <h6 className="mb-0 mt-4" >More Details</h6>
        <Input
          type='textarea'
          name='details'
          id='details'
          className='mb-5'
          maxLength={4000}
          onChange={(e)=>{
            if(e.target.value.length){
              setDesc(e.target.value)
            }else{
              setDesc(undefined)
            }
          }}
          style={{minHeight:'200px'}}
        />
        <button type='submit' className="submitBnt btn btn-success btn-block w-25 mx-auto" onClick={()=>handleSubmit()}>Submit</button>
      </Card>
    </Col>
  );
}

export default Index;
