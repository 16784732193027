import React, { Fragment , useEffect } from 'react';
import NavBar from '../NavBar/NavBar';
import { setActiveNavbarElement } from '../../helpers/actions';
import Footer from '../Footer/Footer';
import Section from './Section';
import ServiceSection from "./Services"
import Aos from 'aos';

const  Services = ()=> {
      setActiveNavbarElement(2)

      useEffect(() => {
        Aos.init();
      }, []);

  return (
      <Fragment>
        <NavBar/>
        <Section />
        <ServiceSection/>
        <Footer/>
      </Fragment>
  );
}

export default Services;