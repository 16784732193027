import React, { Fragment } from 'react';
import { Container, Row, Col, CardBody, Card } from "reactstrap";

const About = () => {
    return (
        <Fragment>
            <section id='about' className="section bg-light" style={{paddingBottom:'200px'}}>
                <Container>
                    <Container className="mt-100 mt-60">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                            <div className="section-title text-center mb-4 pb-2">
                                <h2 className="header mb-4">How do we work ?</h2>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="mt-4 pt-2">
                            <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                                <div className="icons text-center mx-auto">
                                <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody>
                                <h5 className="text-dark">Discussion</h5>
                                <p className="text-muted mb-0">
                                Our process starts with a thorough discussion to strategize effectively for our client's projects.
                                </p>
                                </CardBody>
                            </Card>
                            </Col>
                            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
                            <Card className="features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center">
                                <div className="icons text-center mx-auto">
                                <i className="uil uil-airplay d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody>
                                <h5 className="text-dark">Strategy & Testing</h5>
                                <p className="text-muted mb-0">
                                    Our approach involves strategic planning and rigorous testing to ensure optimal results.
                                </p>
                                </CardBody>
                            </Card>
                            </Col>
                            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
                            <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                                <div className="icons text-primary text-center mx-auto">
                                <i className="uil uil-image-check d-block rounded h3 mb-0"></i>
                                </div>
                                <CardBody>
                                <h5 className="text-dark">Reporting</h5>
                                <p className="text-muted mb-0">
                                    At the final stage, we provide comprehensive reporting to our clients, delivering clear insights and outcomes.
                                </p>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg
                    viewBox="0 0 2880 250"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M720 125L2160 0H2880V250H0V125H720Z"
                        fill="currentColor"
                    ></path>
                    </svg>
                </div>
            </div>
        </Fragment>
    );
}

export default About;