import React ,{ useState } from "react";
import "./index.css"
import { useFormikContext } from "formik";
import { IoMdEye , IoMdEyeOff } from "react-icons/io";

const Index = ({
name = undefined ,
label = 'Name' ,
placeholder= 'input' ,
type = 'text',
maxValue = 100 ,
maxLength = 30,
key = 'key'
}) => {

    const { values , setFieldValue , errors } = useFormikContext();

    const [showPass, setShowPass] = useState(true);

    const handleChange=(e)=>{
        if (type === "phone") {
            const pattern = /^\d{14}$/;
            const isValid = pattern.test(e.value);
            if (!isValid) {
                e.value = e.value.replace(/[^\d]/g, "").slice(0, 14);
            }
        }
        else if ( type === 'amount'){
            const pattern = /^\d*$/ ;
            const isValid = pattern.test(e.value);
            if (!isValid) {
                e.value = e.value.replace(/[^\d]/g, "");
            }
            if (+e.value >= maxValue){
                e.value = maxValue
            }
        }
        setFieldValue(name , e.value.length ? e.value : undefined)
    }

    return (
        <div className="InputHandler">
            <h6 className="InputHandler_label">{label}</h6>
            <input
                name={name}
                type={type==="password" ? (showPass ? "password" : "text"): type}
                key={key}
                value={values[name] || undefined}
                placeholder={placeholder}
                maxLength={maxLength}
                onChange={(e)=>handleChange(e.target)}
            />
            {errors[name] ? <div className="InputHandler_required">{errors[name]}</div> : <></> }
            {
                type === "password" ? 
                (
                    <span className="ShowPass" onClick={(e)=>{
                        setShowPass(!showPass)
                    }}>{ !showPass ? <IoMdEye size={24}/> : <IoMdEyeOff size={24}/> }
                    </span>
                ):(<></>)
            }
        </div>
    );
}

export default Index;
