import React, { Fragment, useEffect } from "react";
import Section from "./Section";
import About from "./About";
import NavBar from "../NavBar/NavBar";
import { setActiveNavbarElement } from "../../helpers/actions";
import AOS from "aos";
import Intro from "./Intro";
import HomeSlider from "./HomeSlider";
import Footer from "../Footer/Footer";

const Index = () => {

  setActiveNavbarElement(1)
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Fragment>
      <NavBar />
      <Section />
      <Intro/>
      <About />
      <HomeSlider/>
      <Footer />
    </Fragment>
  );
}

export default Index;
