import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';

const AboutProduct = ({ activeProduct }) => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Container id='about' className='mb-160'>
        <Row className="align-items-center">
          <Col md={12} lg='6' sm='12'>
            <Slider {...sliderSettings} className="owl-carousel owl-theme">
              {
                activeProduct.images.map((img , key )=>{
                  return(
                    <div className="bg-light w-100 p-5 rounded-md shadow me-lg-5" key={'key' + key}>
                      <img
                        src={img.img}
                        className="img-fluid mx-auto d-block custom-img-slider-m17"
                        alt=""
                        />
                    </div>
                  )
                })
              }
            </Slider>
          </Col>
          <Col md={12} lg='6' sm='12' className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title">
              <h4 className="title mb-4">About the Product</h4>
              <p className="text-muted para-desc">{activeProduct.desc}</p>
              <ul className="list-unstyled text-muted my-4 d-flex flex-wrap">
                {
                  activeProduct.features.map((feature , key )=>{
                    return(
                      <Col  key={key} md='6' lg='6' sm='6' xl='6' xs='12'>
                        <li className="list-inline-item">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>{feature.feature}
                      </li>
                      </Col>
                    )
                  })
                }
              </ul>
            </div>
              {activeProduct.link ?
              <div className='mt-2 w-100 text-center'>
                <a target='blank' href={`https://${activeProduct.link}`} className='fs-4 fw-bold text-success'>Live Preview</a>
              </div> : <></> }
          </Col>
        </Row>
      </Container>
  );
}

export default AboutProduct;
