import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import img from "../../assets/Media/Home.jpg";

const Section = () => {

    return (
        <Fragment>
            <section className="bg-half-80 d-table w-100"
                style={{ background: `url(${img}) center` , backgroundSize:'100%'}}
            >
                <div className="bg-overlay" style={{backdropFilter:'rgba(0, 0, 0, 0.48)'}}></div>
                <Container>
                    <Row className="mt-5 justify-content-center">
                        <Col lg={12} className="text-center">
                            <div className="pages-heading title-heading">
                                <h6 className="text-white"> Welcome to <span className='text-warning fw-bold'>Original</span>, where we deliver innovative tech solutions that transform your ideas into reality and drive success in the digital age.</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
        </Fragment>
    );
}

export default Section;