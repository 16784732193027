import React from 'react';
import { Row, Col } from "reactstrap";
import EmailForm from './EmailForm';

const Contact = () => {
    return (
        <section className="section">
            <div className='mx-4'>
                <Row className="align-items-center justify-content-evenly">
                    <Col lg={8} md={8} className="mt-4 pt-2">
                        <EmailForm/>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Contact;
