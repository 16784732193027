import axios from 'axios'

const API_URL = 'https://api.original-business.com/api/'

    const getBaseHeaders = () => {
        return {
        'Content-Type': "application/json"
        }
    }

    export const API = axios.create({
        baseURL: API_URL,
        headers: getBaseHeaders()
    })
