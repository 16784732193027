import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import {
    Row,
    Col,
    Alert,
    Card,
    CardBody,
    Input,
    Label,
} from "reactstrap";
import InputHandler from "../SharedComponents/Input/input"
import SelectHandler from '../SharedComponents/SelectHandler';
import * as yup from "yup"
import { API } from "../../Api"

const EmailForm = () => {

    const [alert, setAlert] = useState({
        text: '' ,
        color: 'info',
        isOpen : false
    });

    const [key, setkey] = useState(Math.random(10));

    
    const validationSchema = yup.object().shape({
        name:yup.string().required('Enter Name'),
        phone:yup.string().min(10, 'Enter Valid Phone').required('Enter Phone'),
        type:yup.string().required('Enter Type'),
    })

    const handleSubmit = (values , formikProps)=>{
        API.post('/clients',values)
        .then(()=> {
            setAlert({
                text: 'Your Request details sent successfully.',
                color: 'success',
                isOpen: true
            })
            setkey(Math.random(10))
            formikProps.setValues({})
            formikProps.resetForm({})
        })
        .catch((err) => {
            setAlert({
                text: err.response.data.message || 'Failed to send Your Request details',
                color: 'danger',
                isOpen: true
            })
        })
        .finally(()=>{
            setTimeout(() => {
                setAlert({
                    text: '',
                    color: 'info',
                    isOpen: false
                })
            }, 2500);
        })
    }
    
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{}}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values , formikProps )=> handleSubmit(values , formikProps)}
            enableReinitialize
        >
            {(formikProps)=>(
                <Card className="shadow rounded border-0">
                    <CardBody className="py-5">
                        <Alert color={alert.color} isOpen={alert.isOpen} > {alert.text} </Alert>
                        <h4 className="card-title">Get In Touch !</h4>
                        <p className='text-muted text-center'>
                            Contact us today to explore the best web solutions, applications, and graphic design. We are here to turn your ideas into stunning digital reality. Request a free consultation now and let us start achieving your goals with our professional and tailored services
                        </p>
                        <div className="custom-form mt-3">
                        <Form key={key}>
                            <Row>
                            <Col md={6}>
                                <InputHandler
                                    name={'name'}
                                    placeholder='Enter Name'
                                    key='name'
                                />
                            </Col>
                            <Col md={6}>
                                <InputHandler
                                    name={'phone'}
                                    label='Phone'
                                    type='phone'
                                    placeholder='Enter Phone'
                                    key='phone'
                                />
                            </Col>
                            <Col md={12}>
                                <SelectHandler
                                    name={'type'}
                                    key={'type'}
                                    label='Desired Service'
                                    placeholder='Select desired service'
                                    options={[
                                        {label: 'Graphic Design' , value: 'Graphic Design'},
                                        {label: 'Motion Graphics & Video Editing' , value: 'Motion Graphics & Video Editing'},
                                        {label: 'Marketing' , value: 'Marketing'},
                                        {label: 'Web Development' , value: 'Web Development'},
                                        {label: 'Mobile Applications' , value: 'Mobile Applications'},
                                        {label: 'Local Network' , value: 'Local Network'},
                                    ]}
                                />
                            </Col>
                            <Col md={12}>
                                <h6 className="InputHandler_label mb-0" >More Details</h6>
                                <Input
                                    type='textarea'
                                    name='desc'
                                    id='desc'
                                    className='mb-5'
                                    maxLength={4000}
                                    onChange={(e)=>{
                                        if(e.target.value.length){
                                            formikProps.setFieldValue('desc', e.target.value)
                                        }else{
                                            formikProps.setFieldValue('desc', undefined)
                                        }
                                    }}
                                />
                            </Col>
                            </Row>
                            <Row>
                            <Col sm={12} className="text-center">
                                <div className="d-grid">
                                <button type='submit' className="submitBnt btn btn-primary btn-block">Send Message</button>
                                </div>
                                <div id="simple-msg"></div>
                            </Col>
                            </Row>
                        </Form>
                        </div>
                    </CardBody>
                </Card>
            )}
        </Formik>

    );
}

export default EmailForm;
