import React, { Fragment, useState } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import SectionTitle from "../SharedComponents/SectionTtile";
import { FcClapperboard , FcEditImage , FcMultipleSmartphones  , FcAdvertising , FcMultipleDevices , FcGenealogy } from "react-icons/fc";
import { Link } from "react-router-dom";

const services = [
  {
    id: 1,
    icon: <FcEditImage size={48} color="#2f55d4"/>,
    title: "Graphic Design",
    description:
      "We provide graphic design services to meet visual and marketing identity needs, including logo and branding design, and print materials.",
  },
  {
    id: 2,
    icon: <FcClapperboard size={48}/>,
    title: "Motion Graphics & Video Editing",
    description:
    "We offer services in motion graphics and video editing to create stunning visual content that enhances your brand identity."
  },
  {
    id: 3,
    icon: <FcAdvertising  size={48}/>,
    title: "Marketing",
    description:
    "We offer innovative and effective marketing solutions to help you reach your target audience and enhance your market position."
  },
  {
    id: 4,
    icon: <FcMultipleDevices  size={48}/>,
    title: "Web Development",
    description:
    "We design and develop websites tailored to your business needs, helping you achieve your online goals effectively and innovatively."
  },
  {
    id: 5,
    icon: <FcMultipleSmartphones   size={48}/>,
    title: "Mobile Applications" ,
    description:
      "We develop mobile applications that meet your business needs and provide an excellent user experience on mobile devices.",
  },
  {
    id: 6,
    icon: <FcGenealogy  size={48}/>,
    title: "Local Network",
    description:
    "We provide comprehensive local area network solutions for businesses and organizations, high-speed data exchange"
  },
];

const Services = () => {

  return (
    <Fragment>
      <section className="section">
        <Container>
          <SectionTitle
            title="What do We Provide?"
            desc={
              <>
                <span>that can provide everything you need.</span>
                <div className="text-dark">We provide a comprehensive solution for your digital needs, from innovative interface design and advanced applications to reliable and sophisticated networks. Our distinction lies in integrating modern technology with creativity, offering web services, applications, and graphic design with an unparalleled professional touch.</div>
              </>
            }
          />
          <Row>
            {services.map((item, key) => (
              <Col key={key} md={4} className="col-12 mt-5" data-aos='zoom-in' data-aos-duration='1000' data-aos-delay={(key*100) +200}>
                <div className="features feature-primary text-center">
                  <div className="image position-relative d-inline-block">{item.icon}</div>
                  <div className="content mt-4">
                    <h5>{item.title}</h5>
                    <p className="text-muted mb-0 text-start">{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="mt-5 pt-2 text-center" data-aos="fade-up" data-aos-duration='500' data-aos-delay='1000' data-aos-anchor-placement="top-bottom">
            <Link
              to="/contact"
              className="btn btn-outline-primary"
            >
              Let's Start
            </Link>
          </div>
        </Container>
      </section>
    </Fragment>
  );

}

export default Services;
