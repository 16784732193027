import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { textTruncate } from '../../helpers/actions';

const ProductDetails = ({ activeProduct = {} }) => {

  return (
    <section className="bg-half-170 d-table w-100 bg-light" id='details'>
          <Container>
            <Row className="mt-5 mt-sm-0 align-items-center">
              <Col lg={6} md={6}>
                <div className=" me-lg-4">
                  <h1 className="fw-bold mb-3">
                    {" "}
                    {activeProduct.title}{" "}
                  </h1>
                  <h5 className="text-muted mb-0">
                    {textTruncate(activeProduct.desc || '' , 40)}
                  </h5>
                  <div className="mt-4 pt-2">
                    <a href="#about" className="btn btn-soft-primary m-1">
                      Start Now
                    </a>{" "}
                  </div>
                </div>
              </Col>

              <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="bg-white rounded-md">
                  <img src={activeProduct.img} className="img-fluid mx-auto d-block w-100 h-100" alt="Original" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
  );
}

export default ProductDetails;
