import { useFormikContext } from "formik";
import "./index.css"
import Select from 'react-select'
import React from "react";

const SelectHandler = ({
name = undefined ,
label = 'Name' ,
placeholder= 'input' ,
key = 'select',
noOptionsMessage = "No Options" ,
options = [] ,
onChange = ()=>{} ,
}) => {

    const { values , setFieldValue , errors } = useFormikContext();
    const Screen = window.screen.width

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: "1px solid #8E9099",
            borderRadius: "5px",
            outline: "none",
            padding: '2px 5px',
            fontSize: Screen<=500 ? "0.7rem" :"0.85rem",
            width: "100%",
            height: Screen<=500 ? '30px' : '42px' ,
            backgroundColor: "transparent",
            color: "white",
            textAlign:"start",
            cursor:"pointer",
            ":hover":{
                border: "1px solid #8E9099",
                outline:"none"
            },
            boxShadow: "0px 0px 0px 0px #8E9099"
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: Screen <= 500 ? "0.7rem" : "0.8rem",
            cursor: "pointer",
            textAlign: "start",
            backgroundColor: state.isDisabled ? "transparent" : state.isSelected ? "green" : "transparent",
            color: state.isDisabled ? "#8E9099" : state.isSelected ? "white" : "black",
            ":hover": {
            backgroundColor: state.isDisabled ? "transparent" : "#2f55d4",
            color: 'white'
            },
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            fontSize: Screen<=500 ? "0.8rem" : "0.9rem",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#8E9099",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
        })
        };

    return (
        <div className="select_handler">
            <h6 className="select_handler_label">{label}</h6>
            <Select
                options={options}
                isSearchable={true}
                placeholder={placeholder}
                noOptionsMessage={() => noOptionsMessage}
                isClearable={true}
                autoFocus={false}
                key={key}
                id="select_handler"
                defaultValue={values[name]}
                onChange={(e)=>{
                    setFieldValue(name , e?.value)
                    onChange(e);
                }}
                styles={customStyles}
            />
            {errors[name] ? <div className="select_handler_required">{errors[name]}</div> : <></> }
        </div>
    );
}

export default SelectHandler;
